import React, { useState } from 'react';
import classnames from 'classnames';
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext";
import {Link} from 'gatsby';

const MainNav = () => {
    const {getMenu} = useSiteTreeContext();
    const menuItems = getMenu(1);
    const [isExpanded, toggleExpansion] = useState(false)
    return (
        <nav className="mainNav px-4 bg-gray-500">
            <div className="block py-1 lg:hidden">
                <button onClick={() => toggleExpansion(!isExpanded)}
                    className="flex items-center sm:ml-4 px-3 py-2 border rounded text-white border-white hover:text-red-600 hover:border-red-800 self-end">
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>
            <div className={`${ isExpanded ? `max-h-screen` : `max-h-0`} lg:max-h-full w-full flex-grow lg:flex lg:items-center lg:w-auto overflow-hidden transition-all duration-500 ease-in-out`}>
                <div className="text-sm lg:flex-grow">
                    {menuItems.map(item => (

                            <Link key={item.id} to={item.link} className={classnames({
                                current: item.isCurrent,
                                section: item.isSection
                            }) +  ' block py-2 px-4 mt-4 lg:inline-block lg:mt-0 hover:text-white'}>
                                {item.SilverStripeSiteTree.menuTitle}
                            </Link>
                    ))}
                </div>
            </div>
        </nav>
    );
};

export default MainNav;
import React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby"
import MainNav from '../components/MainNav';

import "../css/app.scss"

const Page = ({children}) => {
    const data = useStaticQuery(graphql`
        {
            silverStripeDataObject(className: { eq: "SilverStripe__SiteConfig__SiteConfig"}) {
                SilverStripeSiteConfig {
                    title
                    SocialLinks {
                        BurnbrightSocialLink {
                            name
                            identifier
                            sort
                            url
                        }
                    }
                }
            }

        }
    `);
    // const { title } = data.silverStripeDataObject.SilverStripeSiteConfig;
    const title = 'Astrid Wildner Architektur';
    const socialLinksData = data.silverStripeDataObject.SilverStripeSiteConfig.SocialLinks;

    const socialLinks = socialLinksData.map(item => (
        <span className={'px-1'} key={item.BurnbrightSocialLink.name}>
						<a href={item.BurnbrightSocialLink.url}
                           title={`Astrid Wildner auf ${item.BurnbrightSocialLink.name}`} target="_blank"
                           rel="noopener noreferrer"><i className={item.BurnbrightSocialLink.identifier}></i></a>

					</span>
    ));

    return (
        <div className="max-w-6xl p-0 bg-white mx-auto">
            <div className="bg-gray-300 p-4 sm:p-8 flex flex-row justify-between">
                <div className="logo sm:self-end">
                    <Link to="/">
                        <h1 className="text-bold text-xl sm:text-3xl md:text-4xl text-gray-900 transition-all duration-500 ease-in-out">{title}</h1>
                    </Link>
                </div>
                <div className="flex flex-col items-end">
                    <div className="socialLinks -mx-1 -mt-16 sm:mt-0 opacity-0 sm:opacity-100 transition-all duration-500 ease-in-out">
                        {socialLinks}
                    </div>
                    <div className="logo-zt mt-8 sm:mt-6 transition-all duration-500 ease-in-out"></div>
                </div>


            </div>
            <MainNav/>

            <main className="p-4 sm:p-8">{children}</main>
            <footer className="bg-gray-300 p-4 sm:p-8 mt-8">
                <div className={'socialLinks -px-1 text-right mb-8'}>{socialLinks}</div>
                <div className="text-center text-gray-700 text-sm  ">
                    <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link> | <Link to="/impressum">Impressum</Link>
                    <br/>
                    © {new Date().getFullYear()} {` `}<br className={'sm:hidden'} />
                    <a href="http://www.astridwildner.at">Astrid&nbsp;Wildner&#8209;Kerschbaumer, Graz&nbsp;&#8209;&nbsp;Hallstatt</a>
                    <br className={'md:hidden'} /><span className={'hidden md:inline'}> | </span>Realisiert
                    von der <a href="http://www.netwerkstatt.at" title="Webdesign Bad Ischl" target="_blank"
                               rel="noopener noreferrer"><strong>net</strong>werkstatt</a>
                    <br/>
                    <span className="text-xs">

				</span>

                </div>
            </footer>
        </div>
    )
}

Page.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Page
